import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './styles/index.css';
import './styles/style.scss';

// const root = ReactDOM.createRoot(document.getElementById("root"));
ReactDOM.render(
  <React.StrictMode>
    {/* <ThemeProvider theme={theme}> */}
      {/* <CssBaseline /> */}
      <App />
    {/* </ThemeProvider> */}
  </React.StrictMode>,
  document.getElementById('root'),
);

// "overrides": {
//   "nth-check": "2.1.1",
//   "braces": "3.0.3",
//   "ws": "5.2.4",
//   "webpack-dev-middleware": "5.3.4",
//   "serialize-javascript": "6.0.2",
//   "micromatch": "4.0.6",
//   "express": "4.19.2",
//   "ejs": "3.1.10",
//   "postcss": "8.4.41",
//   "tinymce": "7.2.0",
//   "webpack": "5.94.0",
//   "react": "^18.3.1",
//   "react-dom": "^18.3.1"
// },