import { useMediaQuery } from '@mui/material';
import useScrollToTop from 'Hook/useScrollToTop';
import { ROUTE_LIST } from 'Routes';
import Footer from 'components/Footer';
import FooterNavbar from 'components/Footer/FooterNavbar';
import Header from 'components/Header';
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

const CommonLayout = ({ open, setOpen }) => {
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width:699px)');

  // // This will be temporary fix it will fix in future
  // const html = document.getElementsByTagName('html');
  // if (!isMobile || location.pathname === '/') {
  //   setTimeout(() => {
  //     html[0].style.overflow = 'auto';
  //   }, 20);
  // }
  // console.count('CommonLayout');
  useScrollToTop();
  const pathname = location.pathname;
  const isShowHeaderFooter = () => {
    if (
      isMobile &&
      (pathname === ROUTE_LIST.FAQ ||
        pathname === ROUTE_LIST.LEGAL||
        pathname === ROUTE_LIST.CONTACT_US ||
        pathname === ROUTE_LIST.ABOUT_US ||
        pathname === '/phone-onboarding')
    ) {
      return false;
    }
    return true;
  };

  return (
    <>
      <div style={{ display: isShowHeaderFooter() ? 'block' : 'none' }}>{  <Header open={open} setOpen={setOpen} />}</div>
      <div className={`${isMobile && 'min-h-screen bg-transparent'}`}>
        <Outlet />
      </div>

      {isShowHeaderFooter() && <Footer />}
      <FooterNavbar />
    </>
  );
};

export default CommonLayout;
