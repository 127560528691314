import HOMEDesktopTabletLandscapeHomecontent from 'components/Home';
import { Entermobilegetapp } from 'components/Popup';

const Home = () => {
  return (
    <>
      <Entermobilegetapp />
      <div className='relative flex flex-col w-full mx-auto bg-gray-900 font-hauora sm:bg-inherit'>
        <HOMEDesktopTabletLandscapeHomecontent />
      </div>
    </>
  );
};

export default Home;
